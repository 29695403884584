// API Methods

//Redirect
export const REDIRECT_LINKS = {
    request: "/noteDashboard/",
    manage: "/manage.dashboard/",
    history: "/notePaymentHistory/",
    transactions: "/noteTransactions/",
    profile: "/profile/",
    upgrade: "/upgrade/",
    subscribe: "/subscribe/",
    vendor: "/vendorProfile/",
    properties: "/propertiesDashboard/",
    noteDetail: "/noteDetail/noteCode=",
    orders: "/serviceOrders/"
};

export const JWT = {
    tokenExpTime: 720,
    refreshTokenExpTime: 1240,
    refreshTokenPath: "/api/auth/token",
};

export const NULL_PHOTO =
    "https://dashboard-stage.noteunlimited.com/get-document/null";

export const CSV = {
    resources: "/notes/static/resources/",
    notes: "uploadNotesTemplate.csv",
    properties: "uploadPropertiesTemplate.csv",
    nod: "NOD_Califorina.csv",
    note_payment_history: "uploadNotePaymentHistory.csv"
};

export const withiutImageStyles = [
    "md-layout-item",
    "md-xsmall-size-100",
    "md-small-size-66",
    "md-medium-size-40",
    "md-large-size-40",
    "md-xlarge-size-33",
    "mx-auto",
];

//Router links
export const ROUTER_LINKS = {
    about: {
        path: "/about",
        name: "about",
    },
    not_found: {
        path: "/404",
        name: "notFound",
    },
    contact: {
        path: "/contact",
        name: "contact",
    },
    terms: {
        path: "/terms",
        name: "terms",
    },
    announcements: {
        path: "/announcements",
        name: "announcements",
    },
    trial_expired: {
        path: "/trial-expired",
        name: "trialExpired",
    },
    feedback: {
        path: "/feedback",
        name: "feedback",
    },
    index: {
        path: "/",
        name: "index",
    },
    calculator: {
        path: "/calculator",
        name: "calculator",
    },
    userProfile: {
        path: "/user-profile",
        name: "userProfile",
    },
    register: {
        path: "/register",
        name: "register",
    },
    login: {
        path: "/login",
        name: "login",
    },
    cookies: {
        path: "/cookies",
        name: "cookies",
    },
    personal: {
        path: "/personal",
        name: "personal",
    },
    business: {
        path: "/business",
        name: "business",
    },
    plans_personal: {
        path: "/plans-personal",
        name: "plans_personal",
    },
    alacarte: {
        path: "/alacarte",
        name: "alacarte",
    },
    payment: {
        path: "/payment",
        name: "payment",
    },
    payment_finished: {
        path: "/payment-finished",
        name: "payment-finished",
    },
    profile_personal_info: {
        path: "/profile-personal-info",
        name: "profile-personal-info",
    },
    change_password: {
        path: "/change",
        name: "change",
    },
    verify: {
        path: "/verify",
        name: "verify",
    },
    send_email: {
        path: "/send-email",
        name: "sendEmail",
    },
    redeemingReportForApi: {
        path: "/redeeming-report-for-api/:id",
        name: "redeemingReportForApi",
    },
    summaryReportForApi: {
        path: "/summary-report-for-api/:id",
        name: "summaryReportForApi",
    },
    profile: {
        path: "/profile",
        name: "profile",
    },
    security: {
        path: "/security",
        name: "security",
    },
    your_plan: {
        path: "/your-plan",
        name: "your-plan",
    },
    payments: {
        path: "/payments",
        name: "payments",
    },
    paymentHistory: {
        path: "/payment-history",
        name: "payment-history",
    },
    profile_api: {
        path: "/api-settings",
        name: "api-settings",
    },
    developers: {
        path: "/developers",
        name: "developers",
    },
    vendorContact: {
        path: "/vendor-contact",
        name: "vendor-contact",
    },
    forSale: {
        path: "/for-sale",
        name: "for-sale",
    },
    userGroupSettings: {
        path: "/group-settings",
        name: "group-settings",
    },
    userGroupHistory: {
        path: "/group-history",
        name: "group-history",
    },
    userGroupMembers: {
        path: "/group-members",
        name: "group-members",
    },
    userGroupInvestorProfiles: {
        path: "/investor-profiles",
        name: "investor-profiles",
    },
    editFilter: {
        path: "/edit-filter",
        name: "edit-filter",
    },
    buyerOffers: {
        path: "/purchases-offers",
        name: "purchases-offers",
    },
    sellerOffers: {
        path: "/sales-offers",
        name: "sales-offers",
    },
    investorProfile: {
        path: "/investor-profile",
        name: "investor-profile",
    },
    investorProfileCustom: {
        path: "/investor-profile-custom",
        name: "investor-profile-custom",
    },
    companies: {
        path: "/companies",
        name: "companies",
    },
};

export const PAGES_WITHOUT_SUBSCRIPTION = [
    ROUTER_LINKS.trial_expired.path,
    ROUTER_LINKS.business.path,
    ROUTER_LINKS.personal.path,
    ROUTER_LINKS.plans_personal.path,
    ROUTER_LINKS.payment.path,
    ROUTER_LINKS.payment_finished.path,
    ROUTER_LINKS.payments.path,
    ROUTER_LINKS.your_plan.path,
    ROUTER_LINKS.security.path,
    ROUTER_LINKS.profile.path,
    ROUTER_LINKS.send_email.path,
    ROUTER_LINKS.verify.path,
    ROUTER_LINKS.change_password.path,
    ROUTER_LINKS.calculator.path,
    ROUTER_LINKS.terms.path,
    ROUTER_LINKS.index.path,
    ROUTER_LINKS.login.path,
    ROUTER_LINKS.register.path,
    ROUTER_LINKS.change_password.path,
    ROUTER_LINKS.about.path,
    ROUTER_LINKS.feedback.path,
    ROUTER_LINKS.contact.path,
    ROUTER_LINKS.announcements.path,
    ROUTER_LINKS.cookies.path,
    ROUTER_LINKS.redeemingReportForApi.path,
    ROUTER_LINKS.summaryReportForApi.path,
    ROUTER_LINKS.profile_api.path,
    ROUTER_LINKS.developers.path,
    ROUTER_LINKS.vendorContact.path,
    ROUTER_LINKS.userGroupSettings.path,
];

//External links
export const TWITTER_LINK = "https://twitter.com/note_unlimited",
    YOUTUBE_LINK = "https://www.youtube.com/channel/UC-3aLRKH_MPM0DY0R2szt9Q",
    YOUTUBE_MARKETPLACE_LINK = "https://youtube.com/playlist?list=PLdzmvV14o8KkCv0gjrUEsQFJRxo9IuasC",
    FACEBOOK_LINK = "https://facebook.com/noteswithoutlimits";

//wp
export const WP_LINKS = {
    media: "https://blog.noteunlimited.com/wp-json/wp/v2/media/",
    blogDefault:
        "https://blog.noteunlimited.com/wp-content/uploads/2021/11/blogImage.png",
    blog: "https://blog.noteunlimited.com",
    help: "https://help.noteunlimited.com",
    blogRequest:
        "https://blog.noteunlimited.com/wp-json/wp/v2/posts/?per_page=10&filter[orderby]=date&order=desc",
};

//Calculator

export const ORIGINAL_PRINCIPLE_BALANCE_TEXT = "originalPrincipleBalance",
    RATE_TEXT = "rate",
    PDI_PAYMENT_TEXT = "pdiPayment",
    ORIGINAL_TERM_TEXT = "originalTerm",
    API_KEY = "AIzaSyDsNsilVDDPMLqCuO98pe2qFN80uNOPTgA";
