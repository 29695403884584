<template>
    <side-bar title="Settings" v-if="!isMobile" class="profile-sidebar">
        <template slot="links">
            <sidebar-item opened menu bold :link="{ name: 'Profile', icon: 'account_circle' }">
                <sidebar-item :link="{name: 'Personal Info', path: '/profile',}" />
                <sidebar-item :link="{ name: 'Security', path: '/security', }" />
                <sidebar-item :link="{ name: 'Companies', path: '/companies', }" />
            </sidebar-item>
            <sidebar-item opened menu bold :link="{ name: 'Subscription', icon: 'subscriptions' }">
                <sidebar-item :link="{name: 'Your plan', path: '/your-plan', }" />
                <sidebar-item :link="{ name: 'Payments', path: '/payments', }" />
            </sidebar-item>
            <sidebar-item opened menu bold :link="{ name: 'Developer', icon: 'api' }" v-if="hasAccessToDeveloperPage()" >
                <sidebar-item :link="{ name: 'Api keys', path: '/api-settings', }" />
            </sidebar-item>

            <sidebar-item opened menu bold :link="{ name: 'Groups', icon: 'group' }">
                <sidebar-item :link="{ name: 'Group Settings', path: '/group-settings', }" />
                <sidebar-item v-if="isAdminOfUserGroup()" :link="{ name: 'Group History', path: '/group-history', }" />
                <sidebar-item v-if="isAdminOfUserGroup()" :link="{ name: 'Group Members', path: '/group-members', }" />
                <sidebar-item v-if="hasAccessToUserInvestorProfilesCustom()" :link="{ name: 'Investor Profiles', path: '/investor-profiles', }" />
                <sidebar-item v-if="hasAccessToInvestorProfile()" :link="{ name: 'Investor Profile', path: '/investor-profile', }" />
                <sidebar-item v-if="hasAccessToInvestorProfileCustom()" :link="{ name: 'Investor Profile', path: '/investor-profile-custom', }" />
            </sidebar-item>
        </template>
    </side-bar>
</template>

<script>
import {PRIVILEGES} from "@/layout/Constants";
import Mixins from "@/plugins/basicMixins";
import {UserDataHelper} from "@/api/helpers";

export default {
    mixins: [Mixins.DetectMobile],
    data() {
        return {
            user: null,
            userInGroup: (this.$store && this.$store.state && this.$store.state.UserInfo && this.$store.state.UserInfo.user ? this.$store.state.UserInfo.user.userGroup: null),
        };
    },
    computed: {
        isUserGroupPrivilege() {
            return this.userInGroup;
        },
    },
    watch: {
        '$store.state.UserInfo.user': {
            immediate: true,
            handler(newEl, oldEl) {
                if (newEl) {
                    this.userInGroup = newEl.userGroup;
                }
            }
        }
    },
    methods: {
        hasAccessToDeveloperPage() {
            if (!this.user) {
                return false;
            }
            let hasAccess = UserDataHelper.isPrivilegeExists(this.user, PRIVILEGES.API_CALL);

            return hasAccess;
        },
        hasAccessToInvestorProfile() {
            if (!this.user) {
                return false;
            }
            let hasAccess = UserDataHelper.isPrivilegeExists(this.user, PRIVILEGES.INVESTOR_PROFILE);

            return hasAccess;
        },
        hasAccessToInvestorProfileCustom() {
            if (!this.user) {
                return false;
            }
            let hasAccess = UserDataHelper.isPrivilegeExists(this.user, PRIVILEGES.INVESTOR_PROFILE_CUSTOM);

            return hasAccess;
        },
        hasAccessToUserInvestorProfilesCustom() {
            if (!this.user) {
                return false;
            }
            let hasAccess = UserDataHelper.isPrivilegeExists(this.user, PRIVILEGES.INVESTOR_PROFILES_CUSTOM_ADMIN);

            return hasAccess;
        },
        isAdminOfUserGroup() {
            if (!this.user) {
                return false;
            }
            let hasAccess = this.user.adminGroups && this.user.adminGroups.length > 0;

            return hasAccess;
        },
    },
    created() {
        this.user = UserDataHelper.getUser();
    },
};
</script>
