import http from "@/http-common";
import axios from "axios";
import {
    USER_DETAIL,
    USER_PAYMENTS,
    CHANGE_PASSWORD_PROFILE,
    STARE_AND_CITIES_LIST,
    CANCEL_USER_SUBSCRIPTION,
    PARAMS_FOR_PAYMENT,
    UPDATE_USER,
    CHANGE_CREDIT_CARD,
} from "@/api/Constants";
import Api from "@/api/methods/Api";

class ApiUsersProfile extends Api {
    getUserDetails(token) {
        return http.get(USER_DETAIL, this.headers(token));
    }

    updateUserDetails(data, token) {
        return http.post(UPDATE_USER, data, this.headers(token));
    }

    stripeKey(token) {
        return http.get(PARAMS_FOR_PAYMENT, this.headers(token));
    }

    getUserPayments(token) {
        return http.get(USER_PAYMENTS, this.headers(token));
    }

    cancelSubscription(token) {
        return http.get(CANCEL_USER_SUBSCRIPTION, this.headers(token));
    }

    getCityState(token, zipCode) {
        return http.get(STARE_AND_CITIES_LIST + zipCode, this.headers(token));
    }

    changePassword(_password, token) {
        return http.post(
            CHANGE_PASSWORD_PROFILE,
            {
                password: _password,
            },
            this.headers(token)
        );
    }

    changeCreditCard(_paymentMethodToken, token) {
        return axios
            .create({
                baseURL: process.env.VUE_APP_API_URL + "/",
                headers: {
                    "Content-type": "text/plain",
                },
            })
            .post(CHANGE_CREDIT_CARD, _paymentMethodToken, this.headers(token));
    }

    setUserParameterSetForUser(token, data) {
        return http.post("api/setUserParameterSetForUser", data, this.headers(token));
    }

    getAllParametersForGroup(token) {
        return http.get("api/getAllParametersForGroup", this.headers(token));
    }

    setUserGroupParameters(data) {
        return http.post("api/setUserGroupParameters", data);
    }

    saveInvestorProfile(data) {
        return http.post("/api/saveInvestorProfile", data);
    }

    getInvestorProfileCustom() {
        return http.get("/api/getInvestorProfileCustom");
    }

    getUserInvestorProfileCustom(userId){
        return http.get("/api/getUserInvestorProfileCustom/" + userId);
    }

    saveInvestorProfileCustom(data) {
        return http.post("/api/saveInvestorProfileCustom", data);
    }

}

export default new ApiUsersProfile();
